<template>
  <div>
    <validation-observer ref="formValidation">
      <b-form @submit.prevent>
        <template v-if="creation == true || recipeSelectionId">
          <b-form-group label-for="input-start_at">
            <validation-provider
              #default="{ errors }"
              name="La sélection débute le"
              rules="required"
            >
              <b-form-select v-model="period" placeholder="Sélection">
                <template #first>
                  <b-form-select-option :value="null" disabled>
                    -- Période --
                  </b-form-select-option>
                </template>
                <b-form-select-option
                  v-for="option in constants.WEEKS"
                  :key="option.start_at"
                  :value="option"
                >
                  Semaine {{ option.week_of_year }}
                  ( du
                  {{ $dayjs(option.start_at).format('DD.MM.YYYY') }} au
                  {{ $dayjs(option.end_at).format('DD.MM.YYYY') }}
                  <span v-if="option.is_current == true">- cette semaine</span>
                  )
                </b-form-select-option>
              </b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group id="input-inherit">
            <b-form-checkbox
              v-model="form.inherit"
              :value="true"
              :unchecked-value="false"
            >
              Importer les recettes de la sélection précédente
            </b-form-checkbox>
          </b-form-group>
        </template>
        <hr />
        <div class="row mt-3">
          <div class="col-md-6">
            <b-button
              variant="primary"
              class="mr-1"
              @click.prevent="validationForm"
            >
              Valider
            </b-button>
            <b-button
              variant="light"
              class="mr-1"
              @click.prevent="$emit('update:display', false)"
            >
              Annuler
            </b-button>
          </div>
          <div class="col-md-6 text-right">
            <b-button
              v-if="recipeSelectionId"
              v-b-modal.modal-delete
              variant="danger"
            >
              Supprimer
            </b-button>
          </div>
        </div>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-delete"
      ref="delete-recipe"
      centered
      hide-header-close
      title="Suprimmer la sélection"
    >
      <p class="my-2">Confirmez-vous la suppression de cet élément ?</p>
      <template #modal-footer="{ ok, cancel, hide }">
        <b-button size="sm" variant="danger" @click="deleteRecipe()">
          Confirmer
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="hide('forget')">
          Annuler
        </b-button>
      </template>
    </b-modal>
    <!-- <pre>{{ form }} </pre> -->
    <!-- <pre>{{ period }} </pre> -->
    <!-- <pre>{{ constants }} </pre> -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, double } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BForm,
  BFormGroup,
  BButton,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  name: 'CopyValue',
  components: {
    BForm,
    BFormGroup,
    BButton,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
  },
  inheritAttrs: false,
  props: {
    creation: {
      default: false,
      type: Boolean,
    },
    recipeSelectionId: {
      default: null,
      type: String,
    },
    display: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      required,
      double,
      locale: 'fr',
      period: null,
      form: {
        start_at: null,
        end_at: null,
        inherit: false,
      },
      constants: {},
    }
  },
  watch: {
    recipeSelectionId() {
      this.init()
    },
    period(value) {
      if (value && value.start_at) {
        this.form.start_at = value.start_at
        this.form.end_at = value.end_at
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      const respConstants = await this.$http.get('/constants/', {
        params: { keys: 'WEEKS' },
      })
      this.constants = respConstants.data
    },
    validationForm() {
      this.$refs.formValidation.validate().then((success) => {
        if (success === true && this.creation === true) {
          this.create()
        } else if (success === true && this.recipeSelectionId) {
          this.update()
        }
      })
    },
    async create() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.post(
          '/recipes/selections/',
          { status: 'ENABLED', ...this.form },
          {
            headers: {
              'X-Fields': 'id',
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('created', resp.data)
      } catch (e) {
        console.log(e)
      }
    },
    async update() {
      try {
        const respCsrf = await this.$http.get('/auth/csrf/')
        const resp = await this.$http.put(
          `/recipes/${this.recipeSelectionId}/`,
          this.form,
          {
            headers: {
              'X-Fields': 'id',
              'X-Keys': Object.keys(this.form).join(','),
              'X-CSRF-TOKEN': respCsrf.data.csrf,
            },
          }
        )
        this.$emit('updated', resp.data)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Enregistrement effectué',
              icon: 'SaveIcon',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          }
        )
      } catch (e) {
        console.log(e)
      }
    },
    async deleteRecipe() {
      const respCsrf = await this.$http.get('/auth/csrf/')
      const resp = await this.$http.delete(
        `/recipes/${this.recipeSelectionId}/`,
        {
          headers: {
            'X-Fields': 'id',
            'X-Keys': Object.keys(this.form).join(','),
            'X-CSRF-TOKEN': respCsrf.data.csrf,
          },
        }
      )
      this.$emit('deleted', resp.data)
      this.$refs['delete-recipe'].hide()
    },
  },
}
</script>
