<template>
  <b-card>
    <div class="card-title">
      <div class="row">
        <div class="col-md-6">
          <h4 class="mb-0">{{ title }}</h4>
        </div>
        <div class="col-md-6 text-right">
          <button
            class="btn btn-info btn-icon mr-1"
            @click.prevent="displayForm = !displayForm"
          >
            <feather-icon icon="PlusIcon" />
          </button>
          <button
            class="btn btn-info btn-icon d-none"
            @click.prevent="displayFilters = !displayFilters"
          >
            <feather-icon icon="FilterIcon" />
          </button>
        </div>
      </div>
    </div>
    <div class="m-lg-5 mb-5">
      <selections-filters
        v-if="displayFilters"
        :filters.sync="filters"
        @updated="setPage(1)"
      />
    </div>
    <div v-if="displayForm == true" class="m-lg-5 mb-5">
      <h3>Créer une sélection de recettes</h3>
      <recipe-selection-form
        :creation="true"
        :display.sync="displayForm"
        @created="onCreate"
      />
    </div>
    <table class="table table-hover">
      <tbody>
        <template v-for="selection in selections">
          <tr
            :key="selection.id"
            @click.prevent="$router.push(`/selections/${selection.id}`)"
          >
            <td>
              <h4>
                Semaine
                <span v-if="selection.week_of_year">
                  {{ String(selection.week_of_year).padStart(2, '0') }}
                </span>
                <span class="text-muted">
                  du {{ $dayjs(selection.start_at).format('DD-MM-YYYY') }}
                  au
                  {{ $dayjs(selection.end_at).format('DD-MM-YYYY') }}
                </span>
                <span v-if="$dayjs().week() == selection.week_of_year">
                  - Cette semaine
                </span>
              </h4>
            </td>
            <td>{{ selection.recipes_relations_count }} recette(s)</td>
          </tr>
        </template>
      </tbody>
    </table>
    <pagination-custom
      :page="filters.page"
      :total="pagination.total"
      :per-page="filters.per_page"
      class="text-center mt-3"
      @change="setPage"
    />
    <!-- <pre>{{ selections }}</pre> -->
    <!-- <pre>{{ pagination }}</pre> -->
    <!-- <pre>{{ filters }}</pre> -->
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import RecipeSelectionForm from '@/components/RecipeSelectionForm.vue'
import SelectionsFilters from './SelectionsFilters.vue'

export default {
  components: {
    BCard,
    SelectionsFilters,
    RecipeSelectionForm,
  },
  data() {
    return {
      displayFilters: false,
      displayForm: false,
      selections: [],
      pagination: { total: 0 },
      filters: {
        page: 1,
        per_page: 25,
        term: null,
      },
    }
  },
  computed: {
    title() {
      if (this.pagination.total) {
        return `${this.pagination.total} éléments - page ${this.pagination.page} / ${this.pagination.pages}`
      }
      return '…'
    },
  },
  watch: {
    $route() {
      this.init()
    },
  },
  async created() {
    this.filters = Object.assign(this.filters, this.$route.query)
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get('/recipes/selections/', {
        params: Object.assign(this.filters, this.$route.query),
        headers: {
          'X-Fields':
            'results{id,status,start_at,end_at,week_of_year,recipes_relations_count},meta',
        },
      })
      this.selections = resp.data.results
      this.pagination = resp.data.meta
      this.$forceUpdate()
      this.scrollToTop()
    },
    setPage(page) {
      if (page) {
        this.filters.page = page
      }
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: this.filters,
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onCreate(selection) {
      this.displayForm = false
      this.$router.push(`/selections/${selection.id}/`)
    },
  },
}
</script>

<style></style>
